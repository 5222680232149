import Splide from '@splidejs/splide'
import { isElementInViewport } from '../../assets/scripts/util/functional'

const Component = {
  init() {
    this.testimonialsSlider()
    this.moveElementsToPosition()
  },
  moveElementsToPosition() {
    const getTranslateValue = (container, element) => {
      const containerRect = container[0].getBoundingClientRect()
      const elementRect = element[0].getBoundingClientRect()
      const translateX =
        containerRect.width / 2 -
        elementRect.width / 2 -
        (elementRect.left - containerRect.left)
      const translateY =
        containerRect.height / 2 -
        elementRect.height / 2 -
        (elementRect.top - containerRect.top)
      return `translate(${translateX}px, ${translateY}px)`
    }

    const section = $('.section--testimonials')
    if (!section.length) {
      return
    }
    section.each(function () {
      const objects = $(this).find('.testimonials__decor-img-wrapper')
      const title = $(this).find('.testimonials__title')
      const container = $(this).find('.position-relative')

      if (!objects.length && !title.length) {
        return
      }

      objects.each(function () {
        const $this = $(this)
        $this.css({
          opacity: 0,
          transform: getTranslateValue(container, $this),
        })
      })

      const animateItems = () => {
        objects.each(function () {
          const $item = $(this)
          if (isElementInViewport(title) && !$item.hasClass('is-animated')) {
            $item.addClass('is-animated')
          } else if (
            !isElementInViewport(title) &&
            $item.hasClass('is-animated')
          ) {
            $item.removeClass('is-animated')
          }
        })
      }

      $(window).on('scroll', () => {
        animateItems()
      })
    })
  },
  testimonialsSlider() {
    const sliders = $('.splide.js-testimonials-slider')
    if (sliders.length) {
      const getMaxSlideHeight = splide => {
        var slides = splide.Components.Elements.slides
        var maxHeight = 0
        for (var i = 0; i < slides.length; i++) {
          var slideHeight = 0
          var textElement = slides[i].querySelector('.ts__text')
          if (textElement) {
            slideHeight = textElement.clientHeight
          } else {
            slideHeight = slides[i].clientHeight
          }
          if (slideHeight > maxHeight) {
            maxHeight = slideHeight
          }
        }
        return maxHeight
      }
      sliders.each(function () {
        const sliderType = $(this).data('type')
        const splide = new Splide(this, {
          type: sliderType === 'short' ? 'slide' : 'fade',
          arrows: sliderType !== 'short',
          gap: sliderType === 'short' ? '4rem' : 0,
          pagination: false,
          perPage: 1,
          padding: sliderType === 'short' ? { left: 0, right: '40%' } : 0,
          perMove: 1,
          autoplay: false,
          lazyLoad: false,
          breakpoints:
            sliderType === 'short'
              ? {
                  768: {
                    gap: '1rem',
                    padding: { left: 0, right: '2.5rem' },
                  },
                }
              : {},
        }).mount()

        const text = $('.ts__text', $(this))

        text.height(getMaxSlideHeight(splide))

        $(window).resize(() => {
          setTimeout(() => {
            text.css('height', 'auto')
            text.height(getMaxSlideHeight(splide))
          }, 100)
        })

        splide.on('moved', function (current) {
          const percentage = (100 / splide.length) * (current + 1)
          $('.splide.js-testimonials-slider')
            .find('.splide__pagination-bar')
            .css({
              maxWidth: `${percentage}%`,
            })
        })
      })
    }
  },
}

$(() => {
  Component.init()
})
